<template>
  <v-container v-if="storedPlp">
    <v-row>
      <v-col>
        <MedicineSettingsView
          :medicine="storedPlp"
          class="pa-0"
          @onChange="settings => changeItem(settings)"
          @onChangeDts="changeDts"
          @onHasError="val => setHasError(val)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="11">
        <!--<v-btn class="mr-4">Отменить</v-btn>-->
        <v-btn
          v-if="hasChanges"
          color="primary"
          @click="send"
          :disabled="hasError || !storedPlpId"
          >Сохранить</v-btn
        >
      </v-col>
      <v-col clos="1" v-if="isManager">
        <v-hover v-slot="{ hover }">
          <v-btn
            fab
            dark
            x-small
            depressed
            :color="hover ? 'red lighten-2' : 'grey lighten-3'"
            title="Удалить"
            @click.stop.prevent="openForDelete()"
          >
            <!-- color="red lighten-2" -->
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>
    <WorkDistributionDeleteDialog
      :name="storedPlpMNN"
      :dialog="dialogDelete"
      @onClose="onCloseDeleteDialog"
      @onAction="onDeleteItem"
    />
    <v-divider v-if="isManager" class="my-4" />
    <v-row v-if="isManager">
      <v-col cols="12">
        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon left class="mb-1">mdi-history</v-icon>Последние действия
              по проекту...
            </v-list-item-title>
          </template>

          <v-list-item class="text-pre-wrap">
            <user-actions :search-log="{ plp_id: storedPlpId }" />
          </v-list-item>
        </v-list-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MedicineSettingsView from '@/components/views/MedicineSettingsView.vue'
import WorkDistributionDeleteDialog from '@/components/dialogs/WorkDistributionDeleteDialog.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import UserActions from '@/components/viewLogs/userActions'

export default {
  name: 'MedicineSettings',
  components: {
    UserActions,
    MedicineSettingsView,
    WorkDistributionDeleteDialog,
  },

  data: () => ({
    changes: {
      tasks: [],
    },
    hasError: false,
    dialogDelete: false,
    dts: null,
  }),

  computed: {
    ...mapGetters([
      'isManager',
      'storedPlp',
      'storedPlpMNN',
      'storedPlpId',
      'storedPlpSigner',
    ]),

    medicine() {
      return this.storedPlp
    },

    hasTaskChanges() {
      return this.changes?.tasks?.length > 0 || this.hasSignerChanges
    },

    hasSignerChanges() {
      return (
        this.changes.signerId &&
        this.storedPlpSigner?.id !== this.changes.signerId
      )
    },

    hasChanges() {
      return this.hasTaskChanges || this.dts
    },
  },

  methods: {
    ...mapActions(['DELETE_MEDICINE', 'SAVE_SETUP', 'SET_PLP_DEADLINES']),
    ...mapMutations(['setHasUnsavedChanges']),

    changeDts(dts) {
      this.dts = dts
    },

    changeItem(settings) {
      this.changes = settings
      this.setHasUnsavedChanges(true)
    },

    setHasError(val) {
      this.hasError = val
    },

    openForDelete() {
      this.dialogDelete = true
    },

    async onDeleteItem() {
      if (this.storedPlpId) {
        await this.DELETE_MEDICINE(this.storedPlpId)

        this.docReady = false
        setTimeout(() => {
          this.$router.push('/')
        }, 100)
        this.$toast.success('Удалено')
      }
      this.onCloseDeleteDialog()
    },

    onCloseDeleteDialog() {
      this.dialogDelete = false
    },

    async send() {
      if (this.dts?.length > 0) {
        await this.SET_PLP_DEADLINES({
          plp_id: this.storedPlpId,
          data: this.dts,
        })
        this.dts = null
      }
      if (Object.keys(this.changes)?.length > 0 && this.hasTaskChanges) {
        this.changes.docId = this.storedPlpId
        await this.SAVE_SETUP(this.changes)
        this.setHasUnsavedChanges(false)
      }
      this.$toast.success('Сохранено')
    },
  },
}
</script>
