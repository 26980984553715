<template>
  <div class="user-page">
    <PageTitle title="Пользователи" icon />
    <v-container id="user-page">
      <v-row class="pt-3">
        <v-col cols="6">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            :label="searchLabel"
            hide-details
            clearable
            class="ma-0 pa-0 mr-6"
            style="max-width: 480px"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <FilterWidget class="filter-btn text-end" active="user_page" />
        </v-col>
      </v-row>

      <MultiFilter
        ref="multiFilter"
        filterActiveGroup="user_page"
        :filtersData="FILTER_USERS_PAGE"
        @apply="filterApply"
      />

      <ReferenceTip
        class="py-5 mt-2"
        v-if="this.URN"
        label="Клик по строке открывает редактирование и удаление. Клик по заголовку столбца сортирует таблицу"
      />

      <v-data-table
        :items="users"
        :headers="headers"
        :search="search"
        hide-default-footer
        disable-pagination
        item-key="id"
        no-results-text="Никого не нашли"
        @click:row="openForEdit"
        :loading="!usersList"
        @pagination="pagination"
      >
        <template v-slot:top>
          <v-row>
            <v-col md="8" class="text-left vert-container">
              <span class="vert-btn" v-if="isManager">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      outlined
                      class="mb-1 mx-2"
                      color="blue-grey"
                      @click="openForAdd"
                    >
                      <v-icon color="primary darken-4" small left>
                        mdi-plus
                      </v-icon>
                      Добавить
                    </v-btn>
                  </template>
                  <span> Добавить пользователя </span>
                </v-tooltip>
              </span>
            </v-col>
            <v-col md="4">
              <FilterAlert :isFiltered="isFiltered" />
            </v-col>
          </v-row>
        </template>

        <!-- Заголовки для статусов -->
        <template
          v-for="valType in userPropertiesHeaders"
          v-slot:[`header.`+valType.value]="{ header }"
        >
          <span :key="valType.value">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dense v-bind="attrs" v-on="on" :color="header.color">
                  {{ header.icon }}
                </v-icon>
              </template>
              <span>{{ header.text }}</span>
            </v-tooltip>
          </span>
        </template>

        <!-- Строки -->
        <template #[`item.index`]="{ index }">
          <span class="cursor-pointer">{{ pageStart + index + 1 }}.</span>
        </template>

        <template v-slot:[`item.fio`]="{ value }">
          <span
            :title="value"
            :inner-html.prop="value | shortFIO | highlight(search)"
          />
        </template>

        <template v-slot:[`item.role`]="{ item }">
          <span :inner-html.prop="item.role.title | highlight(search)"></span>
        </template>

        <!-- Общие статусы -->
        <template
          v-for="valType in userPropertiesHeaders"
          v-slot:[`item.`+valType.value]="{ value }"
        >
          <td :key="valType.value" class="pl-2px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span dense v-bind="attrs" v-on="on" class="text-center">
                  <v-icon v-if="value" small :color="valType.color">
                    <!-- :color="value ? 'success' : ''" -->
                    mdi-check
                  </v-icon>
                  <span v-else>&nbsp;-&nbsp;</span>
                </span>
              </template>
              <span>{{ valType.text }}{{ value ? ' - Да' : ' - Нет' }}</span>
            </v-tooltip>
          </td>
        </template>

        <template v-slot:[`item.email`]="{ value }">
          <span :inner-html.prop="value | highlight(search)" />
        </template>

        <template v-slot:[`item.position`]="{ value }">
          <span :inner-html.prop="value | highlight(search)" />
        </template>
      </v-data-table>

      <v-dialog v-if="dialog" v-model="dialog" width="700px" persistent>
        <UserForm :item="item" @close-dialog="closeDialog" />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import ReferenceTip from '@/components/ReferenceTip.vue'
import UserForm from '@/components/forms/UserForm.vue'
import MultiFilter from '@/components/filters/MultiFilter.vue'
import FilterWidget from '@/components/filters/components/FilterWidget.vue'
import FilterAlert from '@/components/filters/components/FilterAlert.vue'
import PERMISSIONS from '@/store/const/userPermissions'
import { FILTER_USERS_PAGE } from '@/store/const/userFilters'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    PageTitle,
    ReferenceTip,
    UserForm,
    FilterWidget,
    MultiFilter,
    FilterAlert,
  },
  data() {
    return {
      userRoleName: '',
      dialog: false,
      item: null,
      showAllUsers: true,
      search: '',
      headers: [],
      pageStart: 0,
      userPropertiesHeaders: [
        {
          text: 'В отделе',
          icon: 'mdi-briefcase',
          value: 'is_in_department',
          color: 'blue lighten-2',
        },
        {
          text: 'В организации',
          icon: 'mdi-home',
          value: 'is_in_company',
          color: 'blue lighten-2',
        },
        {
          text: 'Проводит экспертизу',
          icon: 'mdi-text-box',
          value: 'can_execute_expertise',
          color: 'blue darken-2',
        },
        {
          text: 'Оценивает агентства',
          icon: 'mdi-earth',
          value: 'can_evaluate_agencies',
          color: 'blue darken-2',
        },
        {
          text: 'Ведёт справочник показаний',
          icon: 'mdi-book-open-page-variant',
          value: 'can_manage_dict_gnvlp',
          color: 'purple lighten-2',
        },
      ],
      PERMISSIONS,
      FILTER_USERS_PAGE,
      filters: [],
      isFiltered: false,
    }
  },
  computed: {
    ...mapGetters(['usersList', 'userRole', 'isManager']),
    URN() {
      return this.userRole.toUpperCase() === 'ADMIN'
    },
    users() {
      // return this.showAllUsers
      //   ? this.usersList
      //   : this.usersList.filter(u => u.is_in_company && u.is_in_department)
      let result = [...this.usersList]

      // TODO Завернуть метод в MultiFilter
      if (this.filters && this.filters.permissions) {
        let keys = this.filters.permissions || []

        if (keys.length) {
          result = result.filter(user => {
            return keys.reduce((sum, key) => sum && user[key], true)
          })
        }
      }

      return result
    },
    // switchLabel() {
    //   return this.showAllUsers
    //     ? 'Сейчас: Полный список. Показать отдел?'
    //     : 'Сейчас: Отдел и организация. Показать всех?'
    // },
    searchLabel() {
      return 'Поиск'
      // return this.showAllUsers ? 'Поиск' : 'Поиск по отделу'
    },
  },
  created() {
    this.headers = this.makeHeaders()
  },
  mounted() {
    this.get_users()
    this.isFiltered = this.users.length !== this.usersList.length
  },

  watch: {
    filters() {
      this.isFiltered = !this.isEmptyObject(this.filters)
    },
  },

  methods: {
    ...mapActions(['get_users']),
    makeHeaders() {
      const head = []
      head.push({
        text: '№',
        value: 'index',
        width: '5px',
        align: 'center',
        sortable: false,
        cellClass: 'text--disabled pl-2 pr-2',
        class: 'pl-2 pr-2',
      })
      head.push({
        text: 'ФИО',
        value: 'fio',
        width: '25%',
        cellClass: 'font-weight-medium',
      })
      head.push({ text: 'Должность', value: 'position' })
      this.userPropertiesHeaders.forEach(item => {
        head.push({
          ...item,
          width: 48,
          class: 'pa-0',
          cellClass: 'pa-0',
        })
      })
      head.push({ text: 'Email', value: 'email' })
      head.push({
        text: 'Роль',
        value: 'role',
        width: '20%',
        cellClass: 'text--secondary',
      })

      return head
    },
    openForEdit(item) {
      if (!this.URN) return
      this.item = item
      // this.$set(this, 'item', item)
      this.dialog = true
    },
    openForAdd() {
      this.item = null
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.item = null
    },
    filterApply(val) {
      this.filters = val
    },
    isEmptyObject(obj) {
      let res =
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      return res
    },
    pagination({ pageStart }) {
      this.pageStart = pageStart
    },
  },
}
</script>

<style scoped>
.v-data-table >>> td,
.pointer {
  cursor: pointer;
}
.v-dialog__content {
  align-items: flex-start;
  padding-top: 15vh;
}
.filter-btn {
  cursor: pointer;
  align-self: end;
  position: relative;
  top: 4px;
}
.pl-2px {
  padding-left: 2px !important;
}
.vert-container {
  position: relative;
  min-height: 50px;
}
.vert-btn {
  position: absolute;
  bottom: 16px;
  left: 16px;
}
</style>
