<template>
  <v-card v-if="!showResetPasswordDialog" class="pa-4">
    <v-container>
      <div class="title">{{ actionTitle }} пользователя</div>
      <v-form @submit.prevent="submitHandler" v-if="formData">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              label="ФИО"
              v-model="formData.fio"
              required
              :error-messages="fioErrors"
              :hint="formData.fio | shortFIO"
              persistent-hint
              class="mb-2"
            ></v-text-field>
            <v-select
              v-model="formData.role.id"
              :items="userRoles"
              label="Роль"
              item-text="title"
              item-value="id"
            ></v-select>
            <v-combobox
              label="Должность"
              v-model="formData.position"
              :items="userPositions"
              clearable
            ></v-combobox>
            <v-text-field
              label="Email"
              required
              v-model.trim="$v.formData.email.$model"
              :error-messages="emailErrors"
              @input="$v.formData.email.$touch()"
              @blur="$v.formData.email.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="!showConfirmations">
          <v-row no-gutters>
            <v-col md="6">
              <v-checkbox
                :label="PERMISSIONS.is_in_department"
                v-model="formData.is_in_department"
                hide-details
                class="ma-0 pa-0 mb-2"
              ></v-checkbox>
            </v-col>
            <v-col md="6">
              <v-checkbox
                :label="PERMISSIONS.is_in_company"
                v-model="formData.is_in_company"
                hide-details
                class="ma-0 pa-0 mb-2"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col md="6">
              <v-checkbox
                :label="PERMISSIONS.can_execute_expertise"
                v-model="formData.can_execute_expertise"
                hide-details
                class="ma-0 pa-0 mb-2"
              ></v-checkbox>
            </v-col>
            <v-col md="6">
              <v-checkbox
                :label="PERMISSIONS.can_evaluate_agencies"
                v-model="formData.can_evaluate_agencies"
                hide-details
                class="ma-0 pa-0 mb-2"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-row>
        <v-row no-gutters v-if="!showConfirmations">
          <v-col md="12">
            <v-checkbox
              :label="PERMISSIONS.can_manage_dict_gnvlp"
              v-model="formData.can_manage_dict_gnvlp"
              hide-details
              class="ma-0 pa-0 mb-2"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="!showConfirmations">
          <v-col md="12">
            <v-btn
              type="submit"
              color="primary"
              class="mr-4"
              small
              :loading="saveUserInProgress"
              :disabled="saveUserInProgress"
              >Сохранить</v-btn
            >
            <v-btn @click="closeDialog" class="mr-4" small>Отменить</v-btn>
            <v-btn
              v-if="isOpenForEdit"
              color="error"
              class="mr-2 float-right"
              @click="showDeleteUser"
              small
              >Удалить</v-btn
            >
            <v-btn
              v-if="isOpenForEdit"
              color="error"
              class="mr-4 float-right"
              @click="showResetPassword"
              small
              >Сбросить пароль</v-btn
            >
          </v-col>
          <v-btn v-if="isManager" right icon @click="showActionHistory = true"
            ><v-icon>mdi-history</v-icon></v-btn
          >
          <v-dialog
            width="1000px"
            v-model="showActionHistory"
            v-if="showActionHistory"
          >
            <v-card>
              <user-actions forUser :search-log="{ user_id: item.id }" />
            </v-card>
          </v-dialog>
        </v-row>

        <v-row v-if="showDeleteUserConfirmation" class="mb-4"
          >Внимание! Вы точно хотите удалить этого пользователя?
        </v-row>
        <v-row v-if="showDeleteUserConfirmation">
          <v-btn @click="hideConfirmations" class="mr-4" small>Отменить</v-btn>
          <v-btn
            color="error"
            class="mr-4"
            @click="deleteUser(formData.id)"
            small
            :loading="deleteUserInProgress"
            :disabled="deleteUserInProgress"
            >Удалить</v-btn
          >
        </v-row>

        <v-row v-if="showResetPasswordConfirmation" class="mb-4">
          Внимание! Вы точно хотите сменить этому пользователю пароль? Нажмите
          Нажмите "Сбросить пароль" и следуйте инструкциям
        </v-row>
        <v-row v-if="showResetPasswordConfirmation">
          <v-btn @click="hideConfirmations" class="mr-4" small>Отменить</v-btn>
          <v-btn
            color="error"
            class="mr-4"
            @click="resetPassword(formData.id)"
            small
            :loading="resetPasswordInProgress"
            :disabled="resetPasswordInProgress"
            >Сбросить пароль</v-btn
          >
        </v-row>

        <!--      Вот тут показываем ещё одну строку с ошибкой, если она есть-->
        <v-row v-if="error_message">
          <v-alert outlined type="error" class="my-3">
            {{ error_message }}
          </v-alert>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
  <v-card v-else-if="showResetPasswordDialog" flat>
    <CopyToClipboard :value="link" :user="newUser" @close="closeDialog" />
  </v-card>
</template>

<script>
import PERMISSIONS from '@/store/const/userPermissions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import apiCall from '@/lib/axiosApi'
import CopyToClipboard from '@/components/buttons/CopyToClipboard'
import { copyObject } from '@/lib/objects'
import UserActions from '@/components/viewLogs/userActions'

const TEMPLATE_FORM_DATA = {
  fio: '',
  role_id: -1,
  role: {
    id: -1,
  },
  email: '',
  position: '',
  expertExtension1: false,
  expertExtension2: false,
  is_in_department: false,
  is_in_company: false,
  can_execute_expertise: false,
  can_evaluate_agencies: false,
  can_manage_dict_gnvlp: false,
}

const POSITIONS = [
  'Специалист',
  'Ведущий специалист',
  'Главный специалист',
  'Заместитель начальника отдела',
  'Начальник отдела',
]

export default {
  components: { UserActions, CopyToClipboard },
  props: ['item'],
  validations: {
    formData: {
      fio: { required },
      email: {
        required,
        email,
        async isUnique(value) {
          const eq = this.oldMail === value
          if (!this.isOpenForAdd && eq) return true
          if (!value) return
          const data = await apiCall(
            'users/check-email',
            { email: value },
            'POST'
          )
          return !data.email
        },
      },
    },
  },
  data() {
    return {
      formData: {},
      userPositions: POSITIONS,

      oldMail: '',
      token: '',
      newUser: null, // новый пользователь после создания

      error_message: '', // единая переменная ошибок для этого компонента

      showDeleteUserConfirmation: false, // показать предупреждение удаления юзера
      showResetPasswordConfirmation: false, // показать предупреждение смены пароля юзера
      showResetPasswordDialog: false, // показать диалоговое окно со сменой пароля

      // три переменные для блокировки соответствующих кнопок на время запроса
      deleteUserInProgress: false,
      saveUserInProgress: false,
      resetPasswordInProgress: false,
      PERMISSIONS,

      showActionHistory: false,
    }
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        if (this.isOpenForAdd) {
          this.initFormData()
          return
        }
        this.formData = copyObject(this.item)
        this.oldMail = this.item.email
        this.$v.$touch()
      },
    },
  },
  async created() {
    await this.get_roles()
    if (!this.item) this.initFormData()
  },
  computed: {
    ...mapGetters(['userRoles', 'EXPERT_ID', 'isManager']),

    emailErrors() {
      const errors = []
      if (!this.$v.formData.email.$dirty) return errors
      !this.$v.formData.email.required &&
        errors.push('Это поле не должно быть пустым')
      !this.$v.formData.email.email && errors.push('Введите почту')
      !this.$v.formData.email.isUnique &&
        errors.push('Такой адрес уже используется')

      return errors
    },
    fioErrors() {
      const errors = []
      if (!this.$v.formData.fio.$dirty) return errors
      !this.$v.formData.fio.required &&
        errors.push('Это поле не должно быть пустым')
      return errors
    },
    isOpenForEdit() {
      return Boolean(this.item)
    },
    isOpenForAdd() {
      return !this.isOpenForEdit
    },
    actionTitle() {
      return this.isOpenForEdit ? 'Редактировать' : 'Добавить'
    },
    link() {
      return `${window.location.origin}/reset-password/${this.token}`
    },
    showConfirmations() {
      return (
        this.showResetPasswordConfirmation || this.showDeleteUserConfirmation
      )
    },
  },
  methods: {
    ...mapActions([
      'save_user',
      'get_roles',
      'add_user',
      'delete_user',
      'generateResetPasswordToken',
    ]),
    ...mapMutations(['SET_ERROR', 'set_current_role']),
    initFormData() {
      this.formData = copyObject(TEMPLATE_FORM_DATA)
      this.formData.role.id = this.EXPERT_ID
    },
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.saveUser()
    },
    closeDialog() {
      this.hideConfirmations()
      this.$emit('close-dialog')
      this.formData = null
    },
    showDeleteUser() {
      this.showDeleteUserConfirmation = true
    },
    showResetPassword() {
      this.showResetPasswordConfirmation = true
    },
    hideConfirmations() {
      this.error_message = ''
      this.showDeleteUserConfirmation = false
      this.showResetPasswordConfirmation = false
    },

    async deleteUser(id = null) {
      this.deleteUserInProgress = true
      this.error_message = ''
      try {
        await this.delete_user(id)
        this.$toast.success('Успешно удален')
        this.closeDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.deleteUserInProgress = false
      }
    },

    async saveUser() {
      this.error_message = ''
      this.saveUserInProgress = true
      try {
        this.formData.role_id = this.formData.role.id

        if (this.isOpenForAdd) {
          // если создать пользователя с пустым паролем, ему автоматом будет выдан токен для сброса
          this.formData.password = null

          this.newUser = await this.add_user(this.formData)
          this.$toast.success('Пользователь добавлен')
          // добавили нового юзера и сразу открыли вкладку токен сброса пароля
          this.token = this.newUser.token_reset_password
          this.showResetPasswordDialog = true
        } else {
          await this.save_user(this.formData)
          this.set_current_role(this.formData)
          this.$toast.success('Сохранено.')
          this.closeDialog()
        }
      } catch (err) {
        this.error_message = err
      } finally {
        this.saveUserInProgress = false
      }
    },

    async resetPassword(userId) {
      this.resetPasswordInProgress = true
      this.error_message = ''
      try {
        this.newUser = await this.generateResetPasswordToken(userId)
        this.token = this.newUser.token_reset_password
        this.showResetPasswordDialog = true
      } catch (err) {
        this.error_message = err
      } finally {
        this.resetPasswordInProgress = false
      }
    },
  },
}
</script>
