<template>
  <v-container fluid>
    <PageTitle title="Ручная загрузка заявки PLP" icon />
    <MedicinesUpload />
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import MedicinesUpload from '@/components/MedicinesUpload'

export default {
  name: 'Medicines-Upload-View',
  components: {
    MedicinesUpload,
    PageTitle,
  },
}
</script>
