var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-page"},[_c('PageTitle',{attrs:{"title":"Пользователи","icon":""}}),_c('v-container',{attrs:{"id":"user-page"}},[_c('v-row',{staticClass:"pt-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"ma-0 pa-0 mr-6",staticStyle:{"max-width":"480px"},attrs:{"prepend-icon":"mdi-magnify","label":_vm.searchLabel,"hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('FilterWidget',{staticClass:"filter-btn text-end",attrs:{"active":"user_page"}})],1)],1),_c('MultiFilter',{ref:"multiFilter",attrs:{"filterActiveGroup":"user_page","filtersData":_vm.FILTER_USERS_PAGE},on:{"apply":_vm.filterApply}}),(this.URN)?_c('ReferenceTip',{staticClass:"py-5 mt-2",attrs:{"label":"Клик по строке открывает редактирование и удаление. Клик по заголовку столбца сортирует таблицу"}}):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers,"search":_vm.search,"hide-default-footer":"","disable-pagination":"","item-key":"id","no-results-text":"Никого не нашли","loading":!_vm.usersList},on:{"click:row":_vm.openForEdit,"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"text-left vert-container",attrs:{"md":"8"}},[(_vm.isManager)?_c('span',{staticClass:"vert-btn"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1 mx-2",attrs:{"small":"","outlined":"","color":"blue-grey"},on:{"click":_vm.openForAdd}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary darken-4","small":"","left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Добавить ")],1)]}}],null,false,2588843686)},[_c('span',[_vm._v(" Добавить пользователя ")])])],1):_vm._e()]),_c('v-col',{attrs:{"md":"4"}},[_c('FilterAlert',{attrs:{"isFiltered":_vm.isFiltered}})],1)],1)]},proxy:true},_vm._l((_vm.userPropertiesHeaders),function(valType){return {key:"header."+valType.value,fn:function(ref){
var header = ref.header;
return [_c('span',{key:valType.value},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","color":header.color}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(header.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.text))])])],1)]}}}),{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('span',{staticClass:"cursor-pointer"},[_vm._v(_vm._s(_vm.pageStart + index + 1)+".")])]}},{key:"item.fio",fn:function(ref){
var value = ref.value;
return [_c('span',{attrs:{"title":value},domProps:{"innerHTML":_vm._f("highlight")(_vm._f("shortFIO")(value),_vm.search)}})]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(item.role.title,_vm.search)}})]}},_vm._l((_vm.userPropertiesHeaders),function(valType){return {key:"item."+valType.value,fn:function(ref){
var value = ref.value;
return [_c('td',{key:valType.value,staticClass:"pl-2px"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-center",attrs:{"dense":""}},'span',attrs,false),on),[(value)?_c('v-icon',{attrs:{"small":"","color":valType.color}},[_vm._v(" mdi-check ")]):_c('span',[_vm._v(" - ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(valType.text)+_vm._s(value ? ' - Да' : ' - Нет'))])])],1)]}}}),{key:"item.email",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.position",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}}],null,true)}),(_vm.dialog)?_c('v-dialog',{attrs:{"width":"700px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('UserForm',{attrs:{"item":_vm.item},on:{"close-dialog":_vm.closeDialog}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }