<template>
  <v-container>
    <ReferenceTip v-if="!currentFile || !canSave" class="mb-3 mt-1">
      Выберите квартал / год для проведения экспертизы, затем выберите файл и
      нажмите кнопку "<b>прочитать</b>"
    </ReferenceTip>
    <v-row no-gutters align="center" dense>
      <v-col md="3">
        <v-select
          v-model="plpQuarter"
          label="Квартал"
          class="my-4 mx-2"
          :items="quartersList"
          hide-details
          :error-messages="quarterErrors"
          @blur="$v.plpQuarter.$touch()"
          ref="plpQuarter"
        />
      </v-col>
      <v-col md="3">
        <v-select
          v-model="plpYear"
          label="Год"
          class="my-4 mx-2"
          hide-details
          :items="yearsList"
        />
      </v-col>
      <v-col md="4">
        <v-file-input
          accept=".docx"
          show-size
          hide-details
          label="Файл с описанием препарата ( plp_ )"
          @change="selectFile"
      /></v-col>
      <v-col md="2" class="text-right">
        <v-btn
          color="success"
          class="ml-4 mt-5"
          small
          @click="upload"
          :disabled="!uploadEnable"
          >Прочитать<v-icon right dark>mdi-import</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear
      bottom
      :active="loading"
      :indeterminate="loading"
      color="deep-purple accent-4"
    ></v-progress-linear>
    <hr class="secondary mb-4" v-if="!loading" />

    <v-alert v-if="message" type="error" outlined>{{ message }}</v-alert>
    <v-alert v-if="currentFile && canSave" type="success" outlined dismissible>
      Файл <strong>{{ currentFile.name }}</strong> прочитан успешно
    </v-alert>
    <v-alert v-if="companyNotFound && medicine" type="info" outlined
      >Для заявителя <b>{{ medicine.applicant }}</b> компания не найдена
      <template #append>
        <v-btn
          v-if="selectedCompany"
          color="info"
          @click="addCompanyTitles"
          :disabled="addCompanyTitleInProgress"
          :loading="addCompanyTitleInProgress"
        >
          Добавить синоним
        </v-btn>
      </template>
    </v-alert>
    <ReferenceTip v-if="currentFile && canSave" class="mb-3 mt-1">
      Проверьте правильность распознования заявки plp, компанию заявителя и
      нажмите "<b>создать проект PLP</b>" внизу страницы
    </ReferenceTip>
    <!-- форма просмотрт загруженного PLP  -->
    <PlpDataForm
      v-if="canSave"
      :value="medicine"
      :companies="companiesList"
      :company="company"
      :companiesLoading="company_searching"
      :visibility="fieldVisibility"
      hide-icons
      hide-top-save
      @submit="submitHandler"
      @company-selected="companySelectedHandler"
    >
      <template #footer="{ data, company, readonly }">
        <v-row dense>
          <v-col class="text-right">
            <span class="text-overline mr-5"
              >{{ toRonamic(plpQuarter) }} - квартал, {{ plpYear }} год</span
            >
            <v-btn
              color="primary"
              @click="submitHandler(data, company)"
              :disabled="readonly"
              ><v-icon left>mdi-plus</v-icon>Создать проект PLP
            </v-btn></v-col
          ></v-row
        ></template
      >
    </PlpDataForm>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import * as mammoth from 'mammoth'
import { Element } from 'mammoth/lib/xml/index'
import { MainLpParse, FIELD_VISIBILITY, BR_COSTUL } from '@/store/const/lpParse'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import PlpDataForm from '@/components/plp/PlpDataForm'
import Quarter from '@/lib/quarter'
import ReferenceTip from '@/components/ReferenceTip'

Element.prototype.text = function () {
  if (this.children.length === 0) {
    return ''
  } else if (this.children.length !== 1 || this.children[0].type !== 'text') {
    let result = this.children.reduce(function (sum, current) {
      if (current.type === 'text') {
        return sum + current.value + BR_COSTUL
      } else {
        return sum
      }
    }, '')

    return result.trimRight()
  }
  return this.children[0].value
}

export default {
  name: 'Medicines-Upload',
  components: { PlpDataForm, ReferenceTip },
  validations: {
    plpQuarter: { required },
    plpYear: { required },
  },
  data: () => ({
    plpQuarter: '',
    plpYear: 2022,
    quartersList: [1, 2, 3, 4],

    //видимость полей на форме
    fieldVisibility: {},
    // объект парсинга файала препарата
    medicine: null,
    company: null,
    company_searching: false,
    companyNotFound: false,
    selectedCompany: null,
    addCompanyTitleInProgress: false,

    canSave: false,
    uploadEnable: false,
    currentFile: undefined,
    message: '',
    loading: false,
  }),
  created() {
    this.init()
    this.fieldVisibility = { ...FIELD_VISIBILITY }
    this.get_companies()
  },
  computed: {
    ...mapGetters(['companiesList']),
    quarterErrors() {
      const errors = []
      if (!this.$v.plpQuarter.$dirty) return errors
      !this.$v.plpQuarter.required &&
        errors.push('Это поле не должно быть пустым')
      //this.$refs.plpQuarter.focus()
      return errors
    },
    yearsList() {
      const year = this.getYear()
      return [year, year + 1]
    },
  },
  methods: {
    ...mapMutations(['SET_ERROR']),
    ...mapActions([
      'CREATE_PLP',
      'get_companies',
      'company_search',
      'add_company_title',
    ]),
    init() {
      const q = this.getQuarter() + 1
      this.plpQuarter = q > 4 ? 1 : q
      this.plpYear = this.getYear()
    },
    getQuarter(d) {
      d = d || new Date()
      const quarter = [1, 2, 3, 4]
      return quarter[Math.floor(d.getMonth() / 3)]
    },
    getYear(d) {
      d = d || new Date()
      return d.getFullYear()
    },
    toRonamic(q) {
      return Quarter.asRonamic(q)
    },

    async submitHandler(data, company) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.loading = true
      try {
        const company_id = company?.id ?? null
        await this.CREATE_PLP({
          expertise_year: this.plpYear,
          expertise_quarter: this.plpQuarter,
          data,
          company_id,
          expertise_version: 2,
        })
        this.$toast.success('Документ сохранен')

        await this.$router.push(`/`)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },

    setMedicine(medicine) {
      this.medicine = medicine
      this.company = null
      this.canSave = true
      this.uploadEnable = false
    },
    setError(err) {
      this.message = err.error
      this.SET_ERROR(err)
    },
    async companySearch(title) {
      this.company_searching = true
      this.company = null
      this.companyNotFound = false
      try {
        this.company = await this.company_search(title)
      } catch (err) {
        this.companyNotFound = true
      } finally {
        this.company_searching = false
      }
    },
    companySelectedHandler(company) {
      this.selectedCompany = company
    },
    async addCompanyTitles() {
      this.addCompanyTitleInProgress = true
      try {
        await this.add_company_title({
          companyId: this.selectedCompany.id,
          applicant: this.medicine.applicant,
        })
        this.companyNotFound = false
      } finally {
        this.addCompanyTitleInProgress = false
      }
    },
    async upload() {
      this.$v.$touch()
      let reader = new FileReader()
      const setMedicine = this.setMedicine
      const setError = this.setError
      const file = this.currentFile.name
      const companySearch = this.companySearch

      reader.onloadend = () => {
        mammoth
          .convertToHtml({ arrayBuffer: reader.result })
          .then(function (result) {
            const html = result.value.replace(/[\u00A0\s]+/gm, ' ')
            try {
              const objMedicine = MainLpParse(html)
              objMedicine.file = file
              // распознать компанию?!
              setMedicine(objMedicine)
              companySearch(objMedicine.applicant)
            } catch (error) {
              setError({ head: 'Ошибка разбора документа', error })
            }
          })
          .done()
      }

      reader.readAsArrayBuffer(this.currentFile)
    },
    selectFile(file) {
      this.message = ''
      this.canSave = false
      this.medicine = null
      this.currentFile = file
      this.uploadEnable = Boolean(file)
      //console.log(this.currentFile)
    },
  },
}
</script>
