<template>
  <v-container> <DeadlinesDates /> </v-container>
</template>

<script>
import DeadlinesDates from '../components/deadlines/DeadlinesDates.vue'

export default {
  components: { DeadlinesDates },
  name: 'references-deadlines',
}
</script>
